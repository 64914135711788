var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "420", "max-height": "400", persistent: "" },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "overflow-hidden" },
            [
              _c(
                "v-row",
                { staticClass: "ma-0 ml-4" },
                [
                  _c(
                    "span",
                    {
                      staticClass:
                        "col-10 px-0 grey--text text--darken-4 text-h6 text-truncate",
                    },
                    [_vm._v(_vm._s(_vm.$t("discardTicket")))]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "col-2 my-auto",
                      attrs: { "aria-label": "cross", icon: "" },
                      on: {
                        click: function ($event) {
                          return _vm.closeDialog()
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                ],
                1
              ),
              _c("v-row", [_c("v-divider")], 1),
              _c("v-row", { staticClass: "ma-0 ml-4 mb-10 pt-4" }, [
                _c(
                  "span",
                  {
                    staticClass:
                      "grey--text text--darken-4 text-subtitle-1 font-weight-regular",
                  },
                  [_vm._v(_vm._s(_vm.$t("discardTicketMessage")))]
                ),
              ]),
              _c("v-row", { staticClass: "mb-2" }, [_c("v-divider")], 1),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "text-none rounded-lg elevation-0",
                      attrs: {
                        color: "primary",
                        outlined: "",
                        depressed: "",
                        width: "90",
                      },
                      on: { click: _vm.closeDialog },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "primary text-none rounded-lg elevation-0",
                      attrs: { width: "90", depressed: "" },
                      on: { click: _vm.discardTicket },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("discard")) + " ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }